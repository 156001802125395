@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600&display=swap');

$table-max-screen-size: 995px;
$phone-max-screen-size: 490px;

:root {
    font-size: 17px;
    @media (max-width: $table-max-screen-size) {
        font-size: 15px;
    }
    @media (max-width: $phone-max-screen-size) {
        font-size: 13px;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Inter, ui-sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
        sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
        Noto Color Emoji;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.graphIntroClass {
    min-width: 350px !important;
    width: fit-content;

    .introjs-tooltip-title {
        width: 95%;
    }

    .introjs-tooltiptext {
        font-size: 1.05rem;
    }
}
